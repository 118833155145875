var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._v("\n    ab " + _vm._s(_vm.getPriceVal) + "\n    "),
    _vm.showinfo == "true"
      ? _c("span", { staticClass: "price-info-small" }, [
          _vm._v("Für 5 Nächte in der Nebensaison"),
          _c("br"),
          _vm._v(
            "(ohne Bettwäsche/Handtücher/Endreinigung/Buchungsgebühr und Kurkarten)"
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        ab {{getPriceVal}}
        <span class="price-info-small"
            v-if="showinfo == 'true'">Für 5 Nächte in der Nebensaison<br/>(ohne Bettwäsche/Handtücher/Endreinigung/Buchungsgebühr und Kurkarten)</span>
    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'min-rent',
    	props: {
    		prices: Array,
    		minprice: Number,
    		nights: Number,
    		showinfo: String
    	},
    	computed: {
    		getPriceVal: function() {
    			console.log('Prices::', this.prices);
    			var rentalPrices = [];
    			var today = new Date();
    			var price;
    			if (this.prices) {
    				rentalPrices = this.prices.filter(function(el) {
    					console.log('Today::', today.getTime(), 'fromdate::', Date.parse(el.fromdate), 'tilldate::', Date.parse(el.tilldate));
    					return el.type === 'RENT' && el.season.de === "Nebensaison Fewo" && Date.parse(el.tilldate) >= today.getTime();
    					//return el.type === 'RENT' && Date.parse(el.fromdate) <= today.getTime() && Date.parse(el.tilldate) >= today.getTime();
    				});
    			}
    			console.log('rentalPrices::', rentalPrices);
    			console.log('rentalPrices.length::', rentalPrices.length);
    			if (rentalPrices.length > 0) {
    				price = rentalPrices[0].value;
    				//console.log('unitPrice::', price);

    				return Number((price * 5) / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + " €";
    			}



    			return "";

    		}
    	}
    }
</script>
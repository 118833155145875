var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button", id: _vm.getCompId() }
          },
          [_vm._v(" Verfügbare Sonderangebote")]
        )
      ]),
      _vm._v(" "),
      _c(
        "voffice-popup-dlg",
        {
          attrs: {
            "trigger-id": _vm.getCompId(),
            "apply-label": "Schließen",
            "header-label": "Verfügbare Sonderangebote",
            "call-parent-close-method": true
          }
        },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.getOfferText) } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }